import React, { FC, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import { List, ListItem } from "@mui/material";
import Image from "next/legacy/image";
import Link from "next/link";
import TruHearingLogo from "@/assets/truhearing-logo.svg";

export interface NavMenuItemProps {
  title: string;
  to: string;
}

export const navMenuPages: Array<NavMenuItemProps> = [
  {
    title: "Shop",
    to: "/partners",
  },
  {
    title: "OTC vs Rx",
    to: "/otx-vs-rx",
  },
  {
    title: "Support",
    to: "/support",
  },
];

export const Navbar: FC = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar data-testid="navbar" position="static" color="secondary">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
            <Link href="/" style={{ display: "flex" }}>
              <Image src={TruHearingLogo} alt="Tru Hearing Logo" />
            </Link>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              data-testid="mobile-menu-button"
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Link href="/" style={{ display: "flex" }}>
                <Image src={TruHearingLogo} alt="TruHearing Logo" width={200} height={50} />
              </Link>
            </Box>
          </Box>

          <Drawer anchor="left" open={Boolean(anchorElNav)} onClose={handleCloseNavMenu} sx={{ position: "relative" }}>
            <List id="menu-appbar" sx={{ width: 250, paddingTop: 6, paddingLeft: 2 }} data-testid="mobile-menu-container">
              {navMenuPages.map(page => (
                <ListItem key={`mobile_menu_${page.title}`} onClick={handleCloseNavMenu}>
                  <Link href={page.to} passHref legacyBehavior>
                    <Typography
                      data-testid={`mobile-menu-link`}
                      sx={{ fontSize: 20, fontWeight: 900, lineHeight: "20.8px", paddingTop: 3 }}
                    >
                      {page.title}
                    </Typography>
                  </Link>
                </ListItem>
              ))}
            </List>

            <Typography
              data-testid="mobile-menu-phone"
              sx={{
                fontSize: 20,
                fontWeight: 900,
                lineHeight: "20.8px",
                paddingLeft: 6,
                position: "absolute",
                bottom: 0,
                paddingBottom: 3,
              }}
            >
              <a href="tel:1-833-312-1272" style={{ textDecoration: "none" }}>
                1-833-312-1272
              </a>
            </Typography>
            <IconButton
              size="large"
              aria-label="close drawer"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleCloseNavMenu}
              color="inherit"
              data-testid="mobile-menu-close-button"
              sx={{ position: "absolute", top: 0, left: 10, padding: 2 }}
            >
              <CloseIcon />
            </IconButton>
          </Drawer>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {navMenuPages.map(page => (
              <Link key={`desktop_menu_${page.title}`} href={page.to} passHref legacyBehavior>
                <Button
                  data-testid={`desktop-menu-link`}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, mx: 4, color: "black", display: "block", fontWeight: 900, fontSize: 16 }}
                >
                  {page.title}
                </Button>
              </Link>
            ))}
            <Box sx={{ flexGrow: 1 }} />
            <Button
              href="tel:1-833-312-1272"
              data-testid={`desktop-menu-phone`}
              onClick={handleCloseNavMenu}
              sx={{ my: 2, mx: 4, color: "black", display: "block", fontWeight: 900, fontSize: 16 }}
            >
              1-833-312-1272
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
