import React, { FC } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Link from "next/link";
import Image from "next/legacy/image";
import { Colors } from "@/theme/colors";
import TruHearingLogo from "@/assets/truhearing-logo.svg";

export const Footer: FC = () => (
  <Box
    data-testid="footer"
    sx={{
      padding: { xs: 5, md: 10 },
      backgroundColor: Colors.secondary,
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Box
      sx={{
        display: "flex",
        mr: 1,
        flexDirection: { xs: "column", md: "row" },
        width: 1,
        alignItems: "center",
        justifyContent: "space-between",
        paddingBottom: 2,
      }}
    >
      <Box sx={{ display: "flex", mr: 1, flexDirection: "column", width: { xs: 1, md: 2 / 5 }, pb: 2 }}>
        <Link href="https://www.truhearing.com/" passHref target="_blank" style={{ textDecoration: "none" }}>
          <Image src={TruHearingLogo} alt="Tru Hearing Logo" />
        </Link>
      </Box>
      <Box
        sx={{
          display: "flex",
          mr: 1,
          flexDirection: { xs: "column", md: "row" },
          width: { xs: 1, md: 2 / 5 },
          justifyContent: "space-between",
          textAlign: { xs: "left", md: "right" },
        }}
      >
        <Box>
          <Link
            href="https://www.truhearing.com/about-truhearing/"
            passHref
            target="_blank"
            style={{ textDecoration: "none", color: Colors.black }}
          >
            <Typography sx={{ fontSize: 20, fontWeight: 900, lineHeight: "20.8px", paddingBottom: 2 }}>About TruHearing</Typography>
          </Link>
        </Box>
        <Box>
          <Link
            href="https://www.truhearing.com/resources/"
            passHref
            target="_blank"
            style={{ textDecoration: "none", color: Colors.black }}
          >
            <Typography sx={{ fontSize: 20, fontWeight: 900, lineHeight: "20.8px", paddingBottom: 2 }}>Resources</Typography>
          </Link>
        </Box>
        <Box>
          <Link href="https://www.truhearing.com/contact/" passHref target="_blank" style={{ textDecoration: "none", color: Colors.black }}>
            <Typography sx={{ fontSize: 20, fontWeight: 900, lineHeight: "20.8px", paddingBottom: 2 }}>Contact Us</Typography>
          </Link>
        </Box>
      </Box>
    </Box>

    <Box
      sx={{
        display: "flex",
        mr: 1,
        flexDirection: { xs: "column-reverse", md: "row" },
        width: 1,
        justifyContent: "space-between",
        alignItems: { xs: "center", md: "flex-start" },
      }}
    >
      <Box sx={{ display: "flex", mr: 1, flexDirection: "column", width: { xs: 1, md: 2 / 5 } }}>
        <Typography sx={{ fontSize: 18, fontWeight: 400, lineHeight: "18px" }}>
          THIS IS NOT INSURANCE. All content ©{new Date().getFullYear()} TruHearing, Inc. All Rights Reserved. TruHearing® is a registered
          trademark of TruHearing, Inc. All other trademarks, product names, and company names are the property of their respective owners.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          mr: 1,
          flexDirection: { xs: "column", md: "row" },
          width: { xs: 1, md: 2 / 5 },
          justifyContent: "space-between",
          textAlign: { xs: "left", md: "right" },
        }}
      >
        <Box>
          <Link
            href="https://www.truhearing.com/terms-of-use/"
            passHref
            target="_blank"
            style={{ textDecoration: "none", color: Colors.black }}
          >
            <Typography sx={{ fontSize: 16, fontWeight: 400, lineHeight: "15.36px", paddingBottom: 2 }}>Terms &#38; Conditions</Typography>
          </Link>
        </Box>
        <Box>
          <Link
            href="https://www.truhearing.com/hipaa-notice-of-privacy-practices/"
            passHref
            target="_blank"
            style={{ textDecoration: "none", color: Colors.black }}
          >
            <Typography sx={{ fontSize: 16, fontWeight: 400, lineHeight: "15.36px", paddingBottom: 2 }}>HIPAA Notice</Typography>
          </Link>
        </Box>
        <Box>
          <Link
            href="https://www.truhearing.com/website-privacy-policy/"
            passHref
            target="_blank"
            style={{ textDecoration: "none", color: Colors.black }}
          >
            <Typography sx={{ fontSize: 16, fontWeight: 400, lineHeight: "15.36px", paddingBottom: 2 }}>Privacy Policy</Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  </Box>
);
