import Button, { ButtonProps } from "@mui/material/Button";
import { alpha, styled } from "@mui/material/styles";

export const ContainedButton = styled(Button)<ButtonProps>(() => ({
  // border: "2px solid #006EA8",
  backgroundColor: "#006EA8",
  borderRadius: 25,
  textTransform: "none",
  color: "#FFFFFF",
  "&:hover": {
    // border: "2px solid #005785",
    backgroundColor: "#005785",
    boxShadow: `0px 4px 10px ${alpha("#000", 0.15)}`,
    color: "#FFFFFF",
  },
})) as typeof Button;
