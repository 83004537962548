import React, { FC, PropsWithChildren } from "react";
import { Navbar } from "@/lib/components/navbar";
import { Footer } from "@/lib/components/footer";

export const Layout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <Navbar />
      <div style={{ flex: 1 }}>{children}</div>
      <Footer />
    </div>
  );
};
